@import 'src/vars';

.@{ant-prefix}-card {
  &-checkable {
    width: 100%;
    background-color: var(--card-bg);
    &&-checked:not(&&-with-radio) {
      background-color: var(--card-selected-bg);
      border-color: var(--colors-gray-3);
      box-shadow: var(--card-shadow);
    }
    &&-disabled:not(&-checked) {
      opacity: 0.5;
    }
    &&-with-radio {
      &.@{ant-prefix}-card-checkable-checked {
        border-color: var(--primary-color);
      }
    }
    &-radio {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: transparent;
      border: var(--border-width-base) var(--border-style-base) var(--border-color-base);
      &&:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s;
      }
      &-selected {
        &&:before {
          background-color: var(--radio-dot-color);
        }
      }
    }
  }
}
