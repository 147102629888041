@import 'src/vars';

.@{ant-prefix}-checkable {
  width: 100%;
  margin: 0 !important;
  padding: 12px;
  border: 1px solid var(--input-border-color);
  cursor: pointer;
  transition: border @animation-duration-base;
  background-color: var(--card-bg);
  &.checked {
    background-color: var(--card-selected-bg);
    border-color: var(--colors-gray-3);
    box-shadow: var(--card-shadow);
  }

  .@{ant-prefix}-avatar {
    background-color: var(--border-color-base);
  }
  .@{ant-prefix}-radio-wrapper {
    margin: 0;
  }
  .anticon {
    svg {
      color: var(--primary-color);
    }
  }
}
