@import 'src/vars';

.@{ant-prefix}icon {
  color: var(--icon-color);
  line-height: 1;
  &&-success {
    color: var(--success-color);
  }
  &&-primary {
    color: var(--primary-color);
  }

  &&-warning {
    color: var(--warning-color);
  }

  &&-danger {
    color: var(--danger-color);
  }

  &&-error {
    color: var(--error-color);
  }

  &&-info {
    color: var(--info-color);
  }

  &&-white {
    color: var(--white);
  }

  &&-secondary {
    color: var(--text-color-secondary);
  }
  a,
  a:hover {
    color: var(--text-color);
  }

  &&.@{ant-prefix}-ivy-icon {
    &-success {
      color: var(--success-color);
    }
    &-primary {
      color: var(--primary-color);
    }

    &-warning {
      color: var(--warning-color);
    }

    &-danger {
      color: var(--danger-color);
    }

    &-error {
      color: var(--error-color);
    }

    &-info {
      color: var(--info-color);
    }

    &-white {
      color: var(--white);
    }

    &-default {
      color: var(--text-color);
    }

    &-secondary {
      color: var(--text-color-secondary);
    }
    &-background {
      &-primary {
        background-color: var(--primary-color);
      }

      &-warning {
        background-color: var(--warning-color);
      }

      &-danger {
        background-color: var(--danger-color);
      }

      &-error {
        background-color: var(--error-color);
      }

      &-info {
        background-color: var(--info-color);
      }

      &-light {
        background-color: var(--avatar-bg);
      }

      &-dark {
        background-color: var(--text-color-secondary);
      }

      &-none {
        background-color: transparent;
      }
    }
  }
}
