@import 'src/vars';

.@{ant-prefix}-transactions-table {
  .@{ant-prefix}-table-expanded-row {
    > .@{ant-prefix}-table-cell {
      background-color: transparent;
    }
  }
  .@{ant-prefix}-table {
    &-cell {
      vertical-align: top;
    }
  }
  .@{ant-prefix}-descriptions {
    &-bordered {
      .@{ant-prefix}-descriptions-row {
        &:not(:last-child) {
          .@{ant-prefix}-descriptions-item-label,
          .@{ant-prefix}-descriptions-item-content {
            border-bottom: 1px solid #f0f0f0;
          }
        }
        th:empty {
          display: none;
        }
      }
    }
  }
}
