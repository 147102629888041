@import 'src/vars';

@menu-gradient: rgba(var(--menu-bg-rgb), 0.8);
@menu-gradient-transparent: rgba(var(--menu-bg-rgb), 0);

a,
a.@{ant-prefix}-typography {
  color: var(--link-color);
  &:hover {
    color: var(--link-hover-color);
  }
  &:active {
    color: var(--link-active-color);
  }
}

.@{ant-prefix}-tooltip{
  &-arrow {
    &-content {
      background-color: var(--tooltip-bg);  
    }
  }
  &-inner {
    background-color: var(--tooltip-bg);
  }
  a {
    color: var(--tooltip-color);
  }
}

.@{ant-prefix}-layout-sider {
  .@{ant-prefix}-layout-header,
  .@{ant-prefix}-layout-footer {
    background-color: inherit;
    color: var(--menu-item-color);
  }
  
  &.portal-navigation {
    z-index: 3;
    &.fixed-left {
      min-height: 100vh;
      .@{ant-prefix}-sider-navigation {
        height: 100%;
        // Max Height = 100% Height - (Header Height + Footer Height)
        max-height: calc(100vh - (@layout-header-height * 2));
        overflow-y: scroll;
        padding: 0 0 20px !important;
        &::-webkit-scrollbar {
          display: none;
        }
        .@{ant-prefix}-menu {
          &.@{ant-prefix}-main-menu {
            position: relative;
            padding: 0;
            overflow-y: unset;
            &::-webkit-scrollbar {
              display: none;
            }
            .@{ant-prefix}-menu-item {
              text-align: left;
              &-text {
                margin-left: 0;
                transition: none;
              }
            }
            &.@{ant-prefix}-menu-inline-collapsed {
              width: 100%;
              display: flex;
              flex-flow: column;
              justify-content: center;
            }
          }
        }
      }

      & .@{ant-prefix}-layout-footer,
      & .@{ant-prefix}-layout-header {
        background-color: var(--menu-bg);
        height: @layout-header-height;
      }

      & .@{ant-prefix}-layout-footer::after {
        bottom: @layout-footer-height;
        background: linear-gradient(180deg, @menu-gradient-transparent 0%, @menu-gradient 100%);
        background-color: var(--menu-bg);
      }

      & .@{ant-prefix}-layout-header::after {
        top: var(layout-header-height);
        background: linear-gradient(180deg, white 0%, @menu-gradient 100%);
        background-color: var(--menu-bg);
      }
      
      & .@{ant-prefix}-layout-header {
        // margin-top: 20px;
        padding: 40px;
        & .@{ant-prefix}-portal-navigation-header-link {
          display: flex;
          width: 100%;
          .@{ant-prefix}-logo-icon,
          .@{ant-prefix}-logo-text {
            object-fit: contain;
          }
        }
      }
      &.@{ant-prefix}-layout-sider-collapsed {
        & .@{ant-prefix}-layout-header {
          padding: 40px 24px;
        }
      }
    }
    .@{ant-prefix}-menu {
      &-item {
        padding-left: 0;
        color: var(--menu-item-color);
        a {
          color: var(--menu-item-color);
        }
        &-active {
          background-color: var(--menu-item-hover-bg) !important;
          color: var(--menu-item-hover-color);

          .@{ant-prefix}icon,
          .@{ant-prefix}-menu-item-text
          a {
            color: var(--menu-item-hover-color);
          }
        }
        &-selected,
        &-selected:hover {
          background-color: var(--menu-item-active-bg) !important;
          color: var(--menu-item-active-color) !important;
          .@{ant-prefix}-menu-item-text,
          .@{ant-prefix}icon,
          a {
            color: var(--menu-item-active-color) !important;
          }
        }
        &-active,
        &:hover:not(&-selected),
        &:focus {
          background-color: var(--menu-item-hover-bg);
          color: var(--menu-item-hover-color);
          a,
          .@{ant-prefix}icon {
            color: var(--menu-item-hover-color);
          }
        }

        .@{ant-prefix}icon {
          line-height: 1;
        }
      }
    }
  }

  .@{ant-prefix}-menu {
    width: 100%;
    padding-top: var(--layout-section-padding-vertical);
    padding-bottom: var(--layout-section-padding-vertical);
    transition: background-color 0.2s;
    background: transparent;

    &-inline,
    &-vertical,
    &-vertical-right,
    &-vertical-left {
      border-right: none;
      border-left: none;
    }

    &-horizontal {
      .@{ant-prefix}-menu-item {
        padding: 8px 20px !important;
      }
    }

    &-inline-collapsed {
      .@{ant-prefix}-menu-item {
        .anticon {
          line-height: 1;
        }
      }
    }
    &-item {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding: 0 40px;
      font-weight: var(--menu-item-font-weight);
      font-family: var(--menu-item-font-family);
      font-size: var(--menu-item-font-size);
      line-height: var(--menu-item-height);
      letter-spacing: 0.5px;
      text-transform: var(--menu-item-text-transform);
      border-left: 2px solid transparent;
    }
  }

  .@{ant-prefix}-menu-vertical {
    .@{ant-prefix}-menu-item {
      padding: 0 40px;
    }
  }
}

// .@{ant-prefix}-drawer-left.@{ant-prefix}-drawer-open {
.@{ant-prefix}-drawer {
  &-mobile-sider {
    .@{ant-prefix}-drawer-content-wrapper {
      transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    }
  .@{ant-prefix}-drawer-header-close-only{
    position: absolute;
    .@{ant-prefix}-drawer-close {
      opacity: 0;
    }
  }
  &.@{ant-prefix}-drawer-open {
    .@{ant-prefix}-drawer-header-close-only {
      position: relative !important;
      .@{ant-prefix}-drawer-close {
        opacity: 1;
        top: 40px;
        right: -24px;
        padding: 0;
      }
    }
  }
}
}